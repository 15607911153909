import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from './services/loader/loader.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const allowedUrls = [
      '/api/authentication/login',
      '/api/authentication/password/send_reset_token',
      '/api/authentication/password/reset_password',
      '/api/authentication/dispatch_admin/register',
      '/api/authentication/confirm_email',
      'assets/'
    ];

    if (allowedUrls.some(url => request.url.includes(url))) {
      return next.handle(request);
    }

    const token = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      this.router.navigate(['/auth/login-to-the-system']);
      return throwError(() => new Error('No JWT token found.'));
    }

    this.loaderService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigate(['/auth/login-to-the-system']);
        } else if (error.status >= 400) {
          if (error.error.level === 'WARNING') {
            this.messageService.add({ severity: 'warn', summary: error.error.level, detail: error.error.message, life: 5000 });
            // this.notificationService.warning(error.error.level, error.error.message, 5000);
          } else if (error.url === 'https://map.zigzagplus.com/ors/v2/directions/driving-hgv') {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message, life: 5000 });
            // this.notificationService.error(error.error.level, error.error.error.message, 5000);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message, life: 5000 });
            // this.notificationService.error(error.error.level, error.error.message, 5000);
          }
        }
        return throwError(() => error);
      })
    );
  }
}
